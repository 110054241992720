<template>
	
	<div class="page container">
		<div class="item" @click="go('/CardRead')">
			<i class="iconfont icon-shenfenzheng"></i>
			<p>读卡器复印(仅适用于身份证)</p>
		</div>
		
		<div class="item" @click="go('/Scanning')">
			<i class="iconfont icon-shenfenzheng"></i>
			<p>扫描复印(适用于身份证,银行卡等)</p>
		</div>
		

		
		
	</div>
	
	
</template>

<script>
	export default{
		data(){
			return {}
		},
		methods:{
			go(url) {
				this.$router.push({
					path: url,
				})
			},
		}
	}
	
</script>

<style lang="scss" scoped>
	.item{
		width:30%;
		height:200px;
		background-color: red;
		float:left;
		margin:15px;
		text-align: center;
		padding-top: 30px;
		font-size: 20px;
		color:#fff;
		background-color: rgb(68, 227, 24);
		border-radius: 15px;
		&:nth-child(2){
			background:rgb(89, 221, 241);
		}
		i{
			line-height: 100px;
			font-size: 100px;
			margin-top:50px;
		}
	}
	
	
</style>
